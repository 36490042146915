<template>
  <div class="familia d-flex justify-content-around p-0">
    <img :src="getImage(imgModulo)" class="img-fluid foto" />
    <div class="mx-4 py-4 w80per">
      <h5>{{ familia }}</h5>
      <span> {{ texto }} </span>
    </div>
    <img
      :src="getImage(imgFamilia)"
      class="h100 flia-productos align-self-center"
    />
    <div class="align-self-center mx-4 pointer pdf">
      <a :href="'docs/' + pdf" :download="pdf">
        <img src="@/assets/images/icono-descargar-pdf.png" class="h60 logo-pdf" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "VademecumFamilia",
  props: {
    imgModulo: String,
    familia: String,
    texto: String,
    imgFamilia: String,
    pdf: String,
  },
  methods: {
    getImage(fileName) {
      return require("../assets/images/" + fileName);
    },
  },
};
</script>
