<template>
  <div class="container-fluid bg-gris-claro" id="vademecum">
    <div class="container">
      <div class="row py-4">
        <h5>VADEMECUM</h5>
        <span
          >Descargue todas las características de nuestras familias de
          productos.</span
        >
      </div>
      <div class="row">
        <VademecumFamilia
          imgModulo="cards/cards-modulos-adultos.jpg"
          :familia="$t('vademecum.familia.nca.title')"
          :texto="$t('vademecum.familia.nca.text')"
          imgFamilia="vademecum/vademecum-flia-adultos.png"
          pdf="Vademecum_NutricionClinicaAdultos.pdf"
          
        />

        <VademecumFamilia
          imgModulo="cards/cards-modulos-modulos.jpg"
          :familia="$t('vademecum.familia.mn.title')"
          :texto="$t('vademecum.familia.mn.text')"
          imgFamilia="vademecum/vademecum-flia-modulosnutricionales.png"
          pdf="Vademecum_ModulosNutricionales.pdf"
        />

        <VademecumFamilia
          imgModulo="cards/cards-modulos-pediatricos.jpg"
          :familia="$t('vademecum.familia.ncp.title')"
          :texto="$t('vademecum.familia.ncp.text')"
          imgFamilia="vademecum/vademecum-flia-pediatricos.png"
          pdf="Vademecum_NutricionClinicaPediatricos.pdf"
        />

        <VademecumFamilia
          imgModulo="cards/cards-modulos-cetogenica.jpg"
          :familia="$t('vademecum.familia.dc.title')"
          :texto="$t('vademecum.familia.dc.text')"
          imgFamilia="vademecum/vademecum-flia-cetogenica.png"
          pdf="Vademecum_DietaCetogenica.pdf"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VademecumFamilia from "../components/VademecumFamilia";

export default {
  name: "Vademecum",
  components: {
    VademecumFamilia,
  },
  mounted () {
  window.scrollTo(0, 0)
}
};
</script>